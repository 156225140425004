<template>
    <router-link v-if="$helper.userCapability('update',$route) || $helper.userCapability('view',$route)" :to="{ path }"
                 class="custom-btn" title="Edit item">
        <BootstrapIcon icon="pencil" size="1x"/>
    </router-link>
</template>

<script>
export default {
    name: "EditBtn",
    props: {
        path: {type: String, default: ""},
    },
};
</script>
